import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  activityInProgress,
  fetchActivities,
  fetchActivitiesByContentSummary,
  fetchActivitiesByDaySummary,
} from './activitySlice';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
  Box,
  Fab,
} from '@mui/material';

import { Add as AddIcon } from '@mui/icons-material';
import { Duration } from 'luxon';
import { fetchUsers, selectUser } from '../users/userSlice';
import './activity.scss';
import { history } from '../history/history';
import { UserSelect } from '../users/UserSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ActivityFilters } from './ActivityFilters';
import { useSearchParams } from 'react-router-dom';
import { Botton } from '../../app/Botton';
import { Header } from '../../app/Header';
import { onClickShiftCtrl } from '../history/onClickShiftCtrl';

import { ItemSummary } from './ItemSummary';
import { NoActivity } from './NoActivity';

// MAIN REACT FUNCTION

export const ActivityList = () => {
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();

  /** USER Things */

  let userIdAuth = useSelector((state) => state?.auth?.userId);
  const [userId, setUserId] = useState(userIdAuth);

  const { user: selectedUserId } = useSelector(
    (state) => state?.users?.uiFilters
  );

  // quando usuário copia e cola endereço que tenha ?user=...
  useEffect(() => {
    if (searchParams.get('user')) {
      setUserId(searchParams.get('user'));
      dispatch(selectUser(searchParams.get('user')));
    }
  }, []);

  // pega o usuário logado quando não houver outro selecionado
  useEffect(() => {
    if (!searchParams.get('user') && !selectedUserId) {
      dispatch(selectUser(userIdAuth));
    }
  }, [userIdAuth]);

  useEffect(() => {
    setUserId(selectedUserId);
  }, [selectedUserId]);

  // quando muda o usuário, ajusta o endereço
  useEffect(() => {
    if (userId) {
      history.push({ search: `user=${userId}` });
    }
  }, [dispatch, userId, userIdAuth]);

  //read users
  // confirmar se no componente UserSelect já está buscando a lista de usuários
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
  const users = useSelector((state) => state?.users?.byId);

  /** Other stuff */

  const {
    byGroup,
    withTeacher,
    withoutTeacher,
    byInstPiano,
    byInstAcGuitar,
    onlyMusics,
    exceptMusics,
  } = useSelector((state) => state?.activities?.uiFilters);

  const methods = useForm({
    mode: 'onBlur',
  });

  //fetch conditionally
  useEffect(() => {
    switch (byGroup) {
      case 'content':
        dispatch(fetchActivitiesByContentSummary({ user: users[userId] }));
        break;
      case 'day':
        dispatch(fetchActivitiesByDaySummary({ user: users[userId] }));
        break;
      case 'activity':
        dispatch(fetchActivities());
        break;
      default:
        break;
    }
  }, [
    byGroup,
    withTeacher,
    withoutTeacher,
    byInstPiano,
    byInstAcGuitar,
    onlyMusics,
    exceptMusics,
    userId,
    users,
  ]);

  const activitiesByContent = useSelector(
    (state) => state.activities?.byContent
  );

  const activitiesByDay = useSelector((state) => state.activities?.byDay);

  const handleNewActivity = (activity = {}) => {
    const newActivity = {
      user: activity?.user || null,
      content: activity?.content || null,
    };
    dispatch(activityInProgress(newActivity));
  };

  const renderNewActivity = (activity, similar = false) => {
    const content = similar
      ? 'Criar atividade similar...'
      : 'Criar atividade...';
    return (
      <ListItem>
        <Link to={`/activities/new`}>
          <ListItemSecondaryAction>
            <AddIcon />
          </ListItemSecondaryAction>
        </Link>
        <ListItemText>
          <Link
            to={`/activities/new`}
            onClick={() => {
              handleNewActivity(activity);
            }}
          >
            {content}
          </Link>
        </ListItemText>
      </ListItem>
    );
  };

  const renderSummary = (obj) => {
    if (!obj) return;
    const results = Object.values(obj);

    let count =
      results.length > 1
        ? `${results.length} resultados`
        : `${results.length} resultado`;
    let totalDuration = results.reduce(
      (acc, current) => acc + current.duration,
      0
    );
    totalDuration = Duration.fromMillis(totalDuration).toFormat("hh'h'mm");

    return (
      results.length > 0 && (
        <Alert severity="info">
          {count} - {totalDuration}
        </Alert>
      )
    );
  };

  const renderByGroup = (activitiesGrouped) => {
    if (byGroup === 'content') activitiesGrouped = activitiesByContent;
    if (byGroup === 'day') activitiesGrouped = activitiesByDay;

    if (!activitiesGrouped) return <NoActivity userId={userId} />;
    if (Object.keys(activitiesGrouped).length === 0)
      return <NoActivity userId={userId} />;
    if (!userId) return <NoActivity userId={userId} />;

    const renderItems =
      Array.isArray(activitiesGrouped[userId]) &&
      activitiesGrouped[userId].map((item, index, array) => (
        <ItemSummary
          key={uuidv4()}
          nestedByUser={item}
          userId={users[userId].id}
          byGroup={byGroup}
          index={index}
          array={array}
        />
      ));

    return (
      <React.Fragment key={uuidv4()}>
        {renderSummary(activitiesGrouped[userId])}
        {renderItems}
      </React.Fragment>
    );
  };

  return (
    <div className="AppMaxWidth activity">
      <FormProvider {...methods}>
        <Header title="Atividades" />

        <Box className="user-select" sx={{ mt: '1em' }}>
          <UserSelect />
        </Box>

        {userId && (
          <Box sx={{ margin: '2em 0 2em 0' }}>
            <ActivityFilters />
          </Box>
        )}

        {renderByGroup()}
        <br />
        <br />

        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: '72px',
            right: '16px',
          }}
          onClick={(event) => {
            onClickShiftCtrl(event, '/activities/new');
            handleNewActivity();
          }}
        >
          <AddIcon />
        </Fab>
      </FormProvider>
      <Botton />
    </div>
  );
};
