import React from 'react';
import { Alert } from '@mui/material';

export const NoActivity = ({ userId }) => {
  if (userId)
    return (
      <Alert severity="info" sx={{ mt: '2em' }}>
        Nenhuma atividade encontrada
      </Alert>
    );
};
