import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/studycontrol';
import { history } from '../history/history';
import _ from 'lodash';
import { DateTime } from 'luxon';

// side effects
export const fetchActivities = createAsyncThunk(
  'activities/fetchActivities',
  async () => {
    const response = await api.get('/activities');
    return response.body;
  }
);

export const createActivity = createAsyncThunk(
  'activities/createActivity',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/activities').send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editActivity = createAsyncThunk(
  'activities/editActivity',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api
        .put(`/activities/${formData.id}`)
        .send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchActivity = createAsyncThunk(
  'activities/fetchActivity',
  async (id) => {
    const response = await api.get(`/activities/${id}`);
    return response.body;
  }
);

export const deleteActivity = createAsyncThunk(
  'activities/deleteActivity',
  async (id) => {
    console.log(id);
    try {
      await api.delete(`/activities/${id}`);
    } catch (err) {
      console.log(err);
    }
    return id;
  }
);

const getIsClassParam = (withTeacher, withoutTeacher) => {
  let isClass = undefined;
  let params = '';
  if (withTeacher === true) {
    isClass = true;
  } else if (withoutTeacher === true) {
    isClass = false;
  } else {
    isClass = undefined;
  }
  if (isClass === true) params += `&isClass=true`;
  if (isClass === false) params += `&isClass=false`;
  return params;
};

const getIsMusicParam = (onlyMusics, exceptMusics) => {
  let isMusic;
  let params = '';
  if (onlyMusics === true) {
    isMusic = true;
  } else if (exceptMusics === true) {
    isMusic = false;
  } else {
    isMusic = undefined;
  }

  if (isMusic === true) params += `&content[isMusic]=true`;
  if (isMusic === false) params += `&content[isMusic]=false`;
  return params;
};

export const fetchActivitiesByContentSummary = createAsyncThunk(
  'activities/fetchActivitiesByContentSummary',
  async ({ user, isClass }, { getState }) => {
    let params = '';

    const {
      withTeacher,
      withoutTeacher,
      byInstPiano,
      byInstAcGuitar,
      onlyMusics,
      exceptMusics,
    } = getState().activities?.uiFilters;

    params += getIsClassParam(withTeacher, withoutTeacher);

    if (byInstPiano) params += `&content[instrument]=piano`;
    if (byInstAcGuitar) params += `&content[instrument]=acguitar`;

    params += getIsMusicParam(onlyMusics, exceptMusics);

    const response = await api.get(
      `/activities/byContentSummary/user/${user.id}?${params}`
    );

    const byContent = { [user.id]: response.body };

    return byContent;
  }
);

export const fetchActivitiesByContentDetail = createAsyncThunk(
  'activities/fetchActivitiesByContentDetail',
  async ({ userId, contentId }, { getState }) => {
    const { withTeacher, withoutTeacher } = getState().activities?.uiFilters;

    let params = '';
    params += getIsClassParam(withTeacher, withoutTeacher);

    const response = await api.get(
      `/activities/byContentDetail/user/${userId}/content/${contentId}?${params}`
    );
    // const activities = { userId: response.body };

    return response.body;
  }
);

export const fetchActivitiesByDaySummary = createAsyncThunk(
  'activities/fetchActivitiesByDaySummary',
  async ({ user }, { getState }) => {
    let params = '';
    const {
      withTeacher,
      withoutTeacher,
      byInstPiano,
      byInstAcGuitar,
      onlyMusics,
      exceptMusics,
    } = getState().activities?.uiFilters;

    params += getIsClassParam(withTeacher, withoutTeacher);

    params += getIsMusicParam(onlyMusics, exceptMusics);

    if (byInstPiano) params += `&content[instrument]=piano`;
    if (byInstAcGuitar) params += `&content[instrument]=acguitar`;

    // extract string '-03:00' from current browser offset
    let offset = DateTime.local().toISO().slice(-6);

    const response = await api.get(
      `/activities/byDaySummary/user/${user.id}?tz=${offset}${params}`
    );
    const activities = { [user.id]: response.body };

    return activities;
  }
);

export const fetchActivitiesByDayDetail = createAsyncThunk(
  'activities/fetchActivitiesByDayDetail',
  async ({ userId, day }, { getState }) => {
    let params = '';

    const {
      withTeacher,
      withoutTeacher,
      byInstPiano,
      byInstAcGuitar,
      onlyMusics,
      exceptMusics,
    } = getState().activities?.uiFilters;

    params += getIsClassParam(withTeacher, withoutTeacher);

    params += getIsMusicParam(onlyMusics, exceptMusics);

    if (byInstPiano) params += `&content[instrument]=piano`;
    if (byInstAcGuitar) params += `&content[instrument]=acguitar`;

    // extract string '-03:00' from current browser offset
    let offset = DateTime.local().toISO().slice(-6);

    const response = await api.get(
      `/activities/byDayDetail/user/${userId}/day/${day}?tz=${offset}${params}`
    );
    // const activities = { userId: response.body };

    return response.body;
  }
);

// Export reusable selector here
export const selectActivityById = (id) => (state) =>
  state?.activities?.byId?.[id] || {};

// MAIN FUNCTION

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState: {
    byId: {},
    byContent: {},
    byDay: {},
    showSuccess: false,
    inProgress: {},
    uiFilters: {
      byGroup: 'content',
      isClass: false,
    },
  },
  reducers: {
    activityInProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setUiFilters: (state, action) => {
      Object.assign(state.uiFilters, action.payload);
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.byId = _.mapKeys(action.payload, 'id');
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        history.push(`/activities/edit/${action.payload.id}`);
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(fetchActivity.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
      })
      .addCase(fetchActivity.rejected, (state, action) => {
        console.log();
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        history.push('/activities');
        state.byId = _.omit(state.byId, action.payload);
      })
      .addCase(editActivity.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(fetchActivitiesByContentSummary.fulfilled, (state, action) => {
        state.byContent = action.payload;
      })
      .addCase(fetchActivitiesByContentDetail.fulfilled, (state, action) => {
        state.byContentDetail = action.payload;
      })
      .addCase(fetchActivitiesByDaySummary.fulfilled, (state, action) => {
        state.byDay = action.payload;
      })
      .addCase(fetchActivitiesByDayDetail.fulfilled, (state, action) => {
        state.byDayDetail = action.payload;
      });
    // .addCase(fetchActivitiesByContentDetail.fulfilled, (state, action) => {
    //   state.activities = action.payload;
    // })
    // .addCase(fetchActivitiesByDayDetail.fulfilled, (state, action) => {
    //   state.activities = action.payload;
    // });
  },
});

export const {
  activityInProgress,
  extraReducers,
  setUiFilters,
  setShowSuccess,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
