import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import {
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Button,
  Alert,
  Switch,
} from '@mui/material';
import { history } from '../history/history';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSuccess } from './contentSlice';
import { Botton } from '../../app/Botton';

export const ContentForm = ({ onSubmit, inEditMode = false }) => {
  const { id } = useParams();
  const variant = 'standard';
  const [debug, setDebug] = useState(false);

  const { handleSubmit, control, watch } = useFormContext();

  const { errors, isSubmitting, isDirty, isSubmitted } = useFormState({
    control,
  });

  const showSuccess = useSelector((state) => state?.content?.showSuccess);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDirty || !errors) dispatch(setShowSuccess(false));
  }, [isDirty, errors]);

  const renderSuccess = () =>
    showSuccess && <Alert severity="success">Dados enviados com sucesso</Alert>;

  const renderErrors = () =>
    isSubmitted &&
    Object.keys(errors).length > 0 && (
      <Alert severity="error">
        ERRO: verifique os campos em vermelho abaixo.
      </Alert>
    );

  return (
    <>
      {renderSuccess()}
      {renderErrors()}

      <div className="form-line">
        <Controller
          name="title"
          id="title"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                label="Título"
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                value={field.value || ''}
                inputRef={field.ref}
                error={!!errors[field.name]}
                helperText={errors[field.name]?.message}
                fullWidth
                variant={variant}
              />
            );
          }}
        />
      </div>

      <div className="form-line">
        <FormGroup>
          <FormLabel component="legend">Tipo de conteúdo</FormLabel>
          <Controller
            name="isMusic"
            id="isMusic"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox defaultChecked={field.value} />}
                  label="Música"
                  name={field.name}
                  value={field.value}
                  // inputRef={field.ref}
                  onChange={(event, value) => field.onChange(value)}
                />
              );
            }}
          />
        </FormGroup>
      </div>

      <div>
        <FormLabel component="legend">Instrumentos</FormLabel>
        <Controller
          name="instrument"
          control={control}
          render={({ field }) => {
            return (
              <RadioGroup
                row
                name={field.name}
                value={field.value}
                onChange={(event, value) => field.onChange(value)}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Piano"
                  value="piano"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Violão"
                  value="acguitar"
                />
              </RadioGroup>
            );
          }}
        />
      </div>

      <div className="form-line">
        <FormGroup row>
          <FormLabel component="legend" className="content-size-label">
            Tamanho
          </FormLabel>
          <div className="content-size-fields">
            <Controller
              name="size.seconds"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Segundos"
                    name={field.name}
                    value={field.value || ''}
                    inputRef={field.ref}
                    sx={{ width: '100px' }}
                    variant={variant}
                    onChange={(event, value) =>
                      field.onChange(event.target.value)
                    }
                  />
                );
              }}
            />

            <Controller
              name="size.measures"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Compassos"
                    name={field.name}
                    value={field.value || ''}
                    inputRef={field.ref}
                    sx={{ width: '100px' }}
                    variant={variant}
                    onChange={(event, value) =>
                      field.onChange(event.target.value)
                    }
                  />
                );
              }}
            />

            <Controller
              name="size.phrases"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Frases"
                    name={field.name}
                    value={field.value || ''}
                    inputRef={field.ref}
                    sx={{ width: '100px' }}
                    variant={variant}
                    onChange={(event, value) =>
                      field.onChange(event.target.value)
                    }
                  />
                );
              }}
            />

            <Controller
              name="size.pages"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Páginas"
                    name={field.name}
                    value={field.value || ''}
                    inputRef={field.ref}
                    sx={{ width: '100px' }}
                    variant={variant}
                    onChange={(event, value) =>
                      field.onChange(event.target.value)
                    }
                  />
                );
              }}
            />
          </div>
        </FormGroup>
      </div>

      {!isSubmitting && (
        <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
          Salvar
        </Button>
      )}

      {inEditMode && (
        <>
          <Button
            variant="outlined"
            onClick={() => history.push(`/content/delete/${id}`)}
          >
            Excluir
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setShowSuccess(false));
              history.push(`/content/new`);
            }}
          >
            Novo conteúdo
          </Button>
        </>
      )}

      <FormGroup>
        <FormControlLabel
          control={<Switch checked={debug || false} />}
          label="Debug"
          name="debug"
          onChange={() => setDebug(!debug)}
        />
      </FormGroup>

      {debug === true && (
        <>
          <pre>{JSON.stringify(watch(), 0, 2)}</pre>
          <pre>Erros:</pre>
          <pre>{JSON.stringify(errors, 0, 2)}</pre>
        </>
      )}
      <Botton />
    </>
  );
};
