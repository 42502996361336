import React, { useEffect, useState } from 'react';
import { DateField } from './DateField';
import { TimeField } from './TimeField';
import { DurationField } from './DurationField';
import {
  PlayArrowRounded as PlayIcon,
  StopRounded as StopIcon,
  RestartAlt as RestartIcon,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormControlLabel, FormGroup, Checkbox } from '@mui/material';

export const StartEnd = ({ focused }) => {
  const { control, setValue } = useFormContext();
  let startValue = useWatch({ control, name: 'startTime.value' });
  if (/^\d{4}-\d{2}-\d{2}$/.test(startValue) === true) startValue = undefined;

  const stopValue = useWatch({ control, name: 'stopTime.value' });

  const [showIcon, setShowIcon] = useState('play');

  useEffect(() => {
    if (!startValue) setShowIcon('play');
    if (!!startValue && !stopValue) setShowIcon('stop');
    if (!!startValue && !!stopValue) setShowIcon('restart');
  }, [startValue, stopValue]);

  const [showStopDate, setShowStopDate] = useState(false);
  useEffect(() => {
    if (
      !!startValue &&
      !!stopValue &&
      DateTime.fromISO(startValue).day !== DateTime.fromISO(stopValue).day
    ) {
      setShowStopDate(true);
    }
  }, [startValue]);
  return (
    <>
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showStopDate}
                onChange={() => setShowStopDate(!showStopDate)}
              />
            }
            label="Mostrar data final"
          />
        </FormGroup>
      </>
      <DateField name="startTime.value" label="Início" focused={focused} />
      &nbsp;
      <TimeField name="startTime.value" label="Início" />
      &nbsp; &nbsp;
      <TimeField name="stopTime.value" label="Fim" />
      {showStopDate && (
        <>
          &nbsp;
          <DateField name="stopTime.value" label="Fim" />
        </>
      )}
      &nbsp; &nbsp;
      <DurationField
        name="duration"
        label="Duração"
        startName="startTime.value"
        stopName="stopTime.value"
      />
      {showIcon === 'play' && (
        <PlayIcon
          className="stopwatch-icons"
          onClick={() =>
            setValue(
              'startTime.value',
              DateTime.now().set({ millisecond: 0 }).toISO()
            )
          }
        />
      )}
      {showIcon === 'stop' && (
        <StopIcon
          className="stopwatch-icons"
          onClick={() => {
            setValue(
              'stopTime.value',
              DateTime.now().set({ millisecond: 0 }).toISO()
            );
          }}
        />
      )}
      {showIcon === 'restart' && (
        <RestartIcon
          className="stopwatch-icons"
          onClick={() => {
            setValue('startTime.value', undefined);
            setValue('stopTime.value', undefined);
          }}
        />
      )}
    </>
  );
};
