import React, { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import {
  Card,
  CardHeader,
  CardActionArea,
  Typography,
  Collapse,
  SvgIcon,
} from '@mui/material';
import {
  DateRangeOutlined,
  QueueMusicOutlined,
  ArticleOutlined,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { ItemDetail } from './ItemDetail';
import { NoActivity } from './NoActivity';
import { useDispatch } from 'react-redux';

import { ReactComponent as PianoMusicIcon } from '../../icons/piano-music.svg';
import { ReactComponent as PianoExerciseIcon } from '../../icons/piano-exercise.svg';
import { ReactComponent as AcGuitarMusicIcon } from '../../icons/acoustic-guitar-music.svg';
import { ReactComponent as AcGuitarExerciseIcon } from '../../icons/acoustic-guitar-exercise.svg';

export const ItemSummary = ({
  nestedByUser,
  userId,
  byGroup,
  index,
  array,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (key) => {
    setExpanded({ ...expanded, [key]: !expanded[key] });
  };

  // if (nestedByUser.length === 0) {
  //   return <NoActivity userId={userId} />;
  // }

  let uid = null;
  let title = '';
  let subheader = '';
  let avatar;
  const font = { fontSize: 'large' };

  if (byGroup === 'content') {
    uid = `${nestedByUser?.content?.id}`;
    title = nestedByUser?.content?.title;
    subheader =
      Duration.fromMillis(nestedByUser.duration).toFormat("h'h'mm") +
      ' | ' +
      nestedByUser.activities +
      ' atividades';

    avatar = (
      <>
        {getAvatar(
          'content',
          nestedByUser.content?.isMusic,
          nestedByUser.content?.instrument
        )}
        <Typography
          sx={{ marginLeft: '0.6em', marginRight: '0.3em', fontSize: '2em' }}
        >
          {index + 1}
        </Typography>
      </>
    );
  }

  if (byGroup === 'day') {
    uid = `${nestedByUser.day}`;
    title = `${getDayTitle(nestedByUser).title}`;
    subheader = getDayTitle(nestedByUser).subtitle;
    avatar = (
      <>
        {getAvatar('day')}
        <Typography
          sx={{ marginLeft: '0.5em', marginRight: '0.1em', fontSize: '2em' }}
        >{`${array.length - index}`}</Typography>
      </>
    );
  }

  return (
    <React.Fragment key={uuidv4()}>
      <Card key={uid} sx={{ margin: '1.5em 0' }}>
        <CardActionArea onClick={() => handleExpandClick(uid)}>
          <CardHeader
            avatar={avatar}
            action={
              <ExpandMore
                expand={expanded[uid]}
                onClick={() => handleExpandClick(uid)}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            title={<Typography sx={{ fontSize: '1.5em' }}>{title}</Typography>}
            subheader={
              <Typography
                color="text.secondary"
                component="p"
                className="content-secondary"
              >
                {subheader}
              </Typography>
            }
          />
        </CardActionArea>
        <Collapse in={expanded[uid]} timeout="auto" unmountOnExit>
          <ItemDetail
            byGroup={byGroup}
            userId={nestedByUser?.user}
            contentId={nestedByUser?.content?.id}
            day={nestedByUser.day}
          />
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

/** AUXILIARY FUNCTIONS */

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <KeyboardArrowDown {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const getDayTitle = (groupedDay) => {
  const start = DateTime.fromISO(groupedDay.day, {
    locale: 'pt-BR',
  });
  const duration = Duration.fromMillis(groupedDay.duration);

  let title = '';
  let subtitle = '';
  title += capitalize(start.toFormat('ccc').replace('.', '')) + ' ';
  title += start.toFormat('dd/MM/yy') + ' ';

  subtitle += ' Duração ' + duration.toFormat("h'h'mm");
  subtitle += ' | ' + groupedDay.activities + ' atividades';

  return { title, subtitle };
};

/** capitalize AUXILAR FUNCTION */
const capitalize = (str) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1);
};

const getAvatar = (byGroup, isMusic, instrument) => {
  const font = { fontSize: 'large' };
  if (byGroup === 'day') return <DateRangeOutlined {...font} />;
  if (byGroup === 'content' && isMusic && instrument === 'piano')
    return (
      <SvgIcon {...font}>
        <PianoMusicIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && !isMusic && instrument === 'piano')
    return (
      <SvgIcon {...font}>
        <PianoExerciseIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && isMusic && instrument === 'acguitar')
    return (
      <SvgIcon {...font}>
        <AcGuitarMusicIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && !isMusic && instrument === 'acguitar')
    return (
      <SvgIcon {...font}>
        <AcGuitarExerciseIcon />
      </SvgIcon>
    );

  return <ArticleOutlined {...font} />;
};
