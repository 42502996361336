import { Box, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUiFilters } from './activitySlice';

export const ActivityFilters = () => {
  const dispatch = useDispatch();

  const {
    withTeacher,
    withoutTeacher,
    byGroup,
    byInstPiano,
    byInstAcGuitar,
    onlyMusics,
    exceptMusics,
  } = useSelector((state) => state?.activities?.uiFilters);

  const withTeacherOnClick = () =>
    dispatch(
      setUiFilters({
        withTeacher: !withTeacher,
        withoutTeacher: false,
      })
    );

  const withoutTeacherOnClick = () =>
    dispatch(
      setUiFilters({
        withoutTeacher: !withoutTeacher,
        withTeacher: false,
      })
    );

  const byGroupOnClick = (byGroup) => {
    dispatch(setUiFilters({ byGroup: byGroup }));
  };

  const byInstPianoOnClick = () => {
    dispatch(
      setUiFilters({
        byInstPiano: !byInstPiano,
        byInstAcGuitar: false,
      })
    );
  };

  const byInstAcGuitarOnClick = () => {
    dispatch(
      setUiFilters({
        byInstAcGuitar: !byInstAcGuitar,
        byInstPiano: false,
      })
    );
  };

  const onlyMusicsOnClick = () => {
    dispatch(
      setUiFilters({
        onlyMusics: !onlyMusics,
        exceptMusics: undefined,
      })
    );
  };

  const exceptMusicsOnClick = () => {
    dispatch(
      setUiFilters({
        exceptMusics: !exceptMusics,
        onlyMusics: undefined,
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          mt: '0.3em',
          gap: '0.6em',
        }}
      >
        <Chip
          label="por conteúdo"
          variant={byGroup === 'content' ? 'filled' : 'outlined'}
          onClick={() => byGroupOnClick('content')}
        />
        <Chip
          label="por dia"
          variant={byGroup === 'day' ? 'filled' : 'outlined'}
          onClick={() => byGroupOnClick('day')}
        />
        <Chip
          label="com professor"
          onClick={withTeacherOnClick}
          // size="small"
          variant={withTeacher ? 'filled' : 'outlined'}
        />
        <Chip
          label="sem professor"
          onClick={withoutTeacherOnClick}
          variant={withoutTeacher ? 'filled' : 'outlined'}
        />
        <Chip
          label="piano"
          onClick={byInstPianoOnClick}
          variant={byInstPiano ? 'filled' : 'outlined'}
        />
        <Chip
          label="violão"
          onClick={byInstAcGuitarOnClick}
          variant={byInstAcGuitar ? 'filled' : 'outlined'}
        />
        <Chip
          label="só musicas"
          onClick={onlyMusicsOnClick}
          variant={onlyMusics ? 'filled' : 'outlined'}
        />
        <Chip
          label="exceto musicas"
          onClick={exceptMusicsOnClick}
          variant={exceptMusics ? 'filled' : 'outlined'}
        />
        {/* <Chip
          label="expandir"
          onClick={() => {}}
          // size="small"
          variant="outlined"
        /> */}
      </Box>
    </>
  );
};
