import React, { useEffect, useState } from 'react';
import {
  CardHeader,
  CardContent,
  Divider,
  Chip,
  CardActionArea,
  Typography,
  SvgIcon,
} from '@mui/material';
import {
  AccountCircleOutlined,
  ModeEditOutlined,
  DateRangeOutlined,
  ArticleOutlined,
} from '@mui/icons-material';
import { ReactComponent as PianoMusicIcon } from '../../icons/piano-music.svg';
import { ReactComponent as PianoExerciseIcon } from '../../icons/piano-exercise.svg';
import { ReactComponent as AcGuitarMusicIcon } from '../../icons/acoustic-guitar-music.svg';
import { ReactComponent as AcGuitarExerciseIcon } from '../../icons/acoustic-guitar-exercise.svg';

import { v4 as uuidv4 } from 'uuid';
import { DateTime, Duration } from 'luxon';
import { onClickShiftCtrl } from '../history/onClickShiftCtrl';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchActivitiesByContentDetail,
  fetchActivitiesByDayDetail,
} from './activitySlice';

export const ItemDetail = ({ userId, byGroup = 'content', contentId, day }) => {
  const dispatch = useDispatch();
  // const [activities, setActivities] = useState([]);

  const byContentDetail = useSelector(
    (state) => state?.activities.byContentDetail
  );
  const byDayDetail = useSelector((state) => state?.activities.byDayDetail);

  const activities = byGroup === 'content' ? byContentDetail : byDayDetail;

  useEffect(() => {
    switch (byGroup) {
      case 'content':
        dispatch(
          fetchActivitiesByContentDetail({
            userId,
            contentId,
          })
        );
        break;
      case 'day':
        dispatch(fetchActivitiesByDayDetail({ userId, day }));
        break;
    }
  }, [dispatch]);

  return (
    <>
      {/* {activities?.length === 0 && renderNewActivity()} */}
      {Array.isArray(activities) &&
        activities.map((activity, index, array) => {
          const count =
            byGroup === 'content' ? array.length - index : index + 1;

          const isClassChip = activity.isClass ? (
            <CardContent>
              <Chip
                label="com professor"
                size="small"
                variant="outlined"
                // color="hsl(0,50%, 50%)"
                sx={{
                  mt: '-25px',
                  ml: '5px',
                }}
                avatar={<AccountCircleOutlined />}
              />
            </CardContent>
          ) : (
            ''
          );

          return (
            <React.Fragment key={uuidv4()}>
              <Divider />
              <div>
                <CardActionArea
                  onClick={(event) =>
                    onClickShiftCtrl(event, `/activities/edit/${activity.id}`)
                  }
                  sx={{ padding: '0.5em 0', backgroundColor: '#f1f1f1' }}
                >
                  <CardHeader
                    avatar={
                      <>
                        {getAvatar(
                          byGroup === 'content' ? 'day' : 'content',
                          activity.content.isMusic,
                          activity.content.instrument
                        )}
                        <Typography
                          sx={{ fontSize: '1.8em', marginLeft: '0.2em' }}
                        >
                          {count}
                        </Typography>
                      </>
                    }
                    title={
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ fontSize: '1.2em' }}
                      >
                        {getActivityContent(activity, byGroup).title}
                      </Typography>
                    }
                    subheader={
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        {getActivityContent(activity, byGroup).subtitle}
                      </Typography>
                    }
                    action={
                      <ModeEditOutlined
                        sx={{ color: 'gray' }}
                        fontSize="medium"
                      />
                    }
                  />

                  {!!activity.description && activity.description !== '' && (
                    <CardContent sx={{ margin: '0.7em' }}>
                      <Typography
                        variant="body2"
                        component="pre"
                        sx={{ whiteSpace: 'pre-wrap' }}
                      >
                        {getActivityContent(activity, byGroup).description}
                      </Typography>
                      {/* {isClassChip} */}
                    </CardContent>
                  )}
                  {isClassChip}
                </CardActionArea>
              </div>
            </React.Fragment>
          );
        })}
    </>
  );
};

/** AUXILIARY FUNCTIONS */
const getActivityContent = (activity, byGroup = 'content') => {
  const start = DateTime.fromISO(activity?.startTime?.value, {
    locale: 'pt-BR',
  });
  const stop = DateTime.fromISO(activity.stopTime?.value);
  const duration = Duration.fromMillis(activity.duration);

  let title = '',
    subtitle = '';
  // out += start.toLocaleString(DateTime.DATE_SHORT) + ' ';
  if (byGroup === 'content') {
    title += capitalize(start.toFormat('ccc').replace('.', '')) + ' ';
    title += start.toFormat('dd/MM/yy') + ' ';
  }
  if (byGroup === 'day') {
    title += activity?.content?.title + ' ';
  }
  subtitle += ' Duração ' + duration.toFormat("h'h'mm");
  let endText =
    !stop || !stop.isValid ? '(em andamento)' : stop.toFormat("HH'h'mm");
  subtitle += ' | ' + start.toFormat("HH'h'mm") + '-' + endText;

  const description = activity.description;

  return { title, subtitle, description };
};

/** capitalize AUXILIARY FUNCTION */
const capitalize = (str) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1);
};

const getAvatar = (byGroup, isMusic, instrument) => {
  const sx = { fontSize: '2.4em' };
  if (byGroup === 'day') return <DateRangeOutlined sx={sx} />;
  if (byGroup === 'content' && isMusic && instrument === 'piano')
    return (
      <SvgIcon sx={sx}>
        <PianoMusicIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && !isMusic && instrument === 'piano')
    return (
      <SvgIcon sx={sx}>
        <PianoExerciseIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && isMusic && instrument === 'acguitar')
    return (
      <SvgIcon sx={sx}>
        <AcGuitarMusicIcon />
      </SvgIcon>
    );
  if (byGroup === 'content' && !isMusic && instrument === 'acguitar')
    return (
      <SvgIcon sx={sx}>
        <AcGuitarExerciseIcon />
      </SvgIcon>
    );

  return <ArticleOutlined sx={sx} />;
};
